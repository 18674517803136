.accordion-box {
	display: flex;
	flex-direction: column;
	.accordion-header {
		display: grid;
		grid-gap: 10px;
		grid-template-columns: 1fr auto;
		align-items: center;
		padding: 10px;
		background: var(--gray-darkest);
		border: 1px solid var(--border-color);
		border-radius: 4px;
		box-shadow: 0 9px 8px 0px var(--box-shadow-color);
		&.no-border {
			border: none;
		}
		.dropdown-btn {
			margin-right: 10px;
		}
	}
	.collapsed-accordion-tiers {
		display: flex;
		flex-direction: column;
		align-items: center;
		.tier-one {
			background: var(--panel-body);
			border: 1px solid var(--border-color);
			height: 8px;
			width: 98%;
			border-radius: 0px 0px 2px 2px;
		}
		.tier-two {
			background: var(--panel-body);
			border: 1px solid var(--border-color);
			height: 8px;
			width: 96%;
			border-radius: 0px 0px 2px 2px;
		}
	}
	.extended-accordion {
		// background-color: var(--panel-body);
		background-color: transparent;
	}
}
