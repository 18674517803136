.text-area-container {
	position: relative;
	.text-area {
		background: var(--button-bg-2);
		font-family: inherit;
		border: 1px solid var(--border-color);
		border-radius: 1px;
		color: var(--text);
		font-size: inherit;
		width: 100%;
		padding: 10px 20px 10px 10px;
		box-sizing: border-box;
	}
	.required-indicator {
		position: absolute;
		width: 5px;
		height: 5px;
		border-radius: 25px;
		background: var(--blue);
		top: 15px;
		right: 10px;
	}
}
