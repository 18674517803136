.chart-tooltip {
	box-shadow: 0px 14px 24px 0px rgba(0, 0, 0, 0.6);
	border-color: var(--black);
	table {
		border-collapse: collapse;
		border-radius: 2px;
	}
	thead {
		background-color: var(--data-table-bg-light);
		tr th {
			text-align: left;
			padding: 5px;
		}
	}
	tbody {
		background-color: var(--data-table-bg-dark);
		tr td {
			padding: 5px;
			font-size: var(--font-size-small);
		}
		tr td:first-child {
			color: var(--text-muted);
		}
		tr td:last-child {
			text-align: right;
			font-family: var(--number-font);
		}
	}
}
