.pivot-table {
	overflow: auto;
	.rt-table {
		overflow: auto;
	}
	.rt-tbody {
		overflow: visible;
	}
	.rt-th {
		text-align: center;
	}
	.rt-td.hide {
		display: none;
	}
}
