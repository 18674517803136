$tab-border: var(--bg-1);

.data-filter-bar-container {
	.background-box {
		position: fixed;
		background: var(--bg-1);
		height: calc(var(--data-filter-height) + 20px);
		z-index: 1;
		width: 100%;
		top: var(--header-height);
		left: calc(var(--sidebar-width) + var(--sidebar-expander-width));
	}
	.data-filter-bar {
		display: flex;
		position: fixed;
		height: var(--data-filter-height);
		top: calc(var(--header-height) + 10px);
		z-index: 2;
		width: calc(
			100vw - var(--sidebar-width) - var(--sidebar-expander-width) - 20px
		);
		transition: width 300ms ease;
		justify-content: space-between;
		align-items: center;
		padding: 10px;
		background: var(--bg-2);
		@media screen and (max-width: 1160px) {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: 1fr 1fr;
			grid-gap: 10px;
			height: auto;
			[class*='left'] {
				width: 100%;
			}
		}
		&.expanded {
			width: calc(
				100vw - var(--expanded-sidebar-width) - var(--sidebar-width) - 20px
			);
			transition: width 300ms ease;
		}
		[class*='left'] {
			display: flex;
			align-items: center;
			justify-content: space-between;
			> :not(:last-child) {
				margin-right: 10px;
			}
		}
		[class*='right'] {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			> :not(:first-child) {
				margin-left: 10px;
			}
		}
	}
}
