.alert {
	background-color: var(--alert-bg);
	border-color: var(--alert-bg);
	color: var(--white);
	border-width: 1px;
	border-style: solid;
	padding: 0.75rem 1.25rem;
	border-radius: 0.25rem;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	.multiple-alerts {
		.message {
			display: flex;
			align-items: center;
		}
		.show-more {
			background: transparent;
			border: none;
			outline: none;
			cursor: pointer;
			color: var(--white);
			opacity: 0.7;
			margin: 0 10px;
			&:hover {
				opacity: 1;
			}
			&:focus {
				border-bottom: 1px solid var(--lt-gray-lighter);
			}
		}
	}
	i {
		font-size: var(--font-size-larger);
	}
	&::after {
		box-sizing: border-box;
	}
	.content {
		padding: 5px;
	}
	&.no-fill {
		align-items: center;
	}
	.close {
		float: right;
		font-size: 2rem;
		font-weight: 700;
		right: 0;
		line-height: 1;
		padding: 5px;
		opacity: 0.5;
		background-color: transparent;
		border: 0;
		color: inherit;
		&:hover {
			opacity: 0.75;
			cursor: pointer;
		}
		&.no-fill {
			opacity: 1;
		}
	}
}

.alert-hidden {
	display: none !important;
}

.alert-danger {
	background-color: var(--danger);
	border-color: var(--danger);
	color: var(--white);
	&.no-fill {
		background-color: transparent;
		border: 0;
		color: var(--danger);
	}
}

.alert-success {
	background-color: var(--success);
	border-color: var(--success);
	color: var(--black);
	&.no-fill {
		background-color: transparent;
		border: 0;
		color: var(--success);
	}
}
