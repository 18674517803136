.expander-arrow {
	cursor: pointer;
	position: absolute;
	z-index: 2;
	top: 20px;
	right: 0;
	border: none;
	background: transparent;
	transition: opacity 300ms ease, left 300ms ease;
	&:focus {
		outline: none;
		span[class^='icon'] > .path2 {
			color: var(--blue);
		}
	}
	span[class^='icon'] {
		font-size: 22px;
		transition: color, font-size, linear 200ms;
		.path1 {
			color: var(--expander-bg);
		}
		.path2 {
			color: var(--expander-circle);
		}
		.path3 {
			color: var(--expander-arrow);
		}
		&:hover {
			font-size: 24px;
			.path1 {
				color: var(--expander-bg-hover);
			}
			.path2 {
				color: var(--expander-circle-hover);
			}
			.path3 {
				color: var(--expander-arrow-hover);
			}
		}
	}
}
