.pagination {
	width: 100%;
	display: flex;
	padding-top: 15px;
	//justify-content: center;
	height: 100%;
	.pagination-buttons {
		margin-right: 5px;
	}
	.page-number {
		display: flex;
		margin-right: 5px;
		align-self: center;
		.page-index {
			margin-right: 5px;
			margin-left: 5px;
		}
		.page-length {
			margin-left: 5px;
		}
	}
	.go-to-page-span {
		display: flex;
		.go-to-page-div {
			margin-right: 5px;
			align-self: center;
		}
		.go-to-page-input {
			width: 80px;
			margin-right: 5px;
		}
	}
	.page-select {
		background: var(--bg-2);
		color: var(--text);
		border: 1px solid var(--border-color);
	}
}
