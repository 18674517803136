@import url('https://fonts.googleapis.com/css?family=Lato|Nunito+Sans|Roboto+Mono');

$content-height: calc(100vh - (#{var(--header-height)}));

html {
	font-size: (10 / 16) * 100%; //62.5%; sets base font-size to 10px for earier rem calcs.
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	margin: 0;
	overflow: hidden;
	font-family: var(--default-font);
	font-weight: normal;
	font-size: var(--font-size-default); // set default font-size at 16px;
	color: var(--text);
}

h1 {
	font-size: var(--font-size-xxl);
	font-weight: normal;
	margin: 0px;
	font-weight: normal;
	letter-spacing: -1.5px;
	margin-block-start: 0;
	margin-block-end: 0;
}

h2 {
	font-size: var(--font-size-xl);
	font-weight: normal;
	letter-spacing: -0.5px;
	margin: 0px;
	margin-block-start: 0;
	margin-block-end: 0;
}

h3 {
	font-size: var(--font-size-larger);
	font-weight: 600;
	margin-block-start: 0;
	margin-block-end: 0;
}

h4 {
	font-size: var(--font-size-large);
	font-weight: 600;
	margin-block-start: 0;
	margin-block-end: 0;
}

h4 {
	font-size: var(--font-size-large);
	font-weight: 600;
	margin-block-start: 0;
	margin-block-end: 0;
}

a {
	text-decoration: none;
	color: var(--primary);
}

button {
	font: var(--default-font);
}

a:hover {
	text-decoration: none;
	color: var(--primary-dark);
}
a:visited {
	text-decoration: none;
}
*:focus {
	outline: 2px solid rgba(123, 127, 136, 0.5);
}

.hide {
	// visibility: hidden;
	display: none;
}
.white-shadow {
	filter: drop-shadow(2px 4px 6px white);
}

// Scrollbars
::-webkit-scrollbar {
	width: 7px;
	height: 7px;
}
::-webkit-scrollbar-track {
	// background: var(--bg-1);
	background: transparent;
}
::-webkit-scrollbar-corner {
	background: var(--bg-1);
}
::-webkit-scrollbar-thumb {
	background: var(--scrollbar);
	border-radius: 4px;
	&:hover {
		background: var(--scrollbar-hover);
	}
}
::-webkit-scrollbar-button {
	height: 0;
	width: 0;
}

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: var(--text-muted);
}
::-moz-placeholder {
	/* Firefox 19+ */
	color: var(--text-muted);
}
:-ms-input-placeholder {
	/* IE 10+ */
	color: var(--text-muted);
}
:-moz-placeholder {
	/* Firefox 18- */
	color: var(--text-muted);
}
