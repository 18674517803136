.add-btn {
	.display-container {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 8px;
		span {
			color: var(--text-muted);
			margin-right: 10%;
		}
	}
}
