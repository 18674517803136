.landing-page-container {
	text-align: center;
	height: calc(100vh - var(--header-height));
	display: flex;
	justify-content: center;
	align-items: center;
	.quantalytixs-logo {
		margin-left: 5px;
	}
}
