.slider {
	position: relative;
	height: 80px;
	.rail {
		position: absolute;
		width: 100%;
		height: 10px;
		margin-top: 35px;
		border-radius: 5;
		background-color: #8b9cb6;
	}
	.slider-handles {
		position: absolute;
		margin-top: 25px;
		z-index: 2;
		width: 5px;
		height: 30px;
		border: 0;
		text-align: center;
		cursor: pointer;
		background-color: #2c4870;
		color: #333;
		border-radius: 5;
	}
	.slider-tracks {
		position: absolute;
		height: 10px;
		z-index: 1;
		margin-top: 35px;
		background-color: #546c91;
		cursor: pointer;
	}
}
