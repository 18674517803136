.tooltip-container {
	position: relative;
	.tooltip {
		visibility: hidden;
		position: absolute;
		width: 150px;
		height: fit-content;
		white-space: pre-line;
		background-color: var(--button-bg-3); //$gray;
		color: var(--text);
		font-size: smaller;
		text-align: left;
		padding: 5px 5px;
		border-radius: 4px;
		bottom: 40px;
		right: 5px;
		z-index: 1;
		box-shadow: 0 0 1px 0px rgb(0, 0, 0);
		transition: 0.5s ease-in;
		&.show {
			visibility: visible;
		}
	}
	.icon {
		position: absolute;
		top: 0;
		right: 0;
		color: var(--text-muted);
		cursor: pointer;
		z-index: 1;
		transition: opacity 0.5s ease-in;
		-webkit-transition: opacity 0.5s ease-in;
		-moz-transition: opacity 0.5s ease-in;
		-o-transition: opacity 0.5s ease-in;
		&.fade-in {
			opacity: 1;
		}
		&.fade-out {
			opacity: 0;
		}
	}
	&.inline {
		display: flex;
		.tooltip {
			left: 150px;
			bottom: 25px;
		}
		.icon {
			position: relative;
			margin-left: 5px;
		}
	}
}

.tooltip-container:hover .tooltip.hoverable {
	visibility: visible;
}
