.sidebar {
	grid-area: sidebar;
	background: var(--sidebar-bg);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	position: absolute;
	z-index: 5;
}
