$content-height: calc(100vh - (#{var(--header-height)}));

.auth-layout {
	height: $content-height;
	background-image: url('../assets/images/home-bg2.png');
	background-size: cover;
	display: flex;
	justify-content: center;
	.component-container {
		display: flex;
		align-items: center;
		padding: 10px;
		@media screen and (max-width: 700px) {
			align-items: flex-start;
		}
	}
}
