#tabs {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
	font-size: var(--font-size-small);
	.tab {
		cursor: pointer;
		background: transparent;
		border: none;
		padding: 0 0 5px 0;
		margin: 0 5px;
		white-space: nowrap;
		text-overflow: ellipsis;
		outline: none;
		color: var(--text-muted);
		&.header-tab {
			color: var(--navigation-text-muted);
			&#selected {
				color: var(--navigation-text);
			}
		}
	}
	#selected {
		border-bottom-width: 2px;
		border-bottom-style: solid;
		color: var(--text);
	}
}
