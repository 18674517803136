.sidebar-icon-container {
	height: var(--sidebar-width);
	width: var(--sidebar-width);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	img,
	i {
		transition: all ease-in-out 150ms;
	}
	img {
		height: 33px;
	}
	i {
		font-size: x-large;
		color: var(--icon-color);
	}
	&.selected {
		&[name='Scorecards'] {
			background: var(--scorecard-gradient);
		}
		&[name='Pipeline'] {
			background: var(--pipeline-gradient);
		}
		&[name='Reporting'] {
			background: var(--reporting-gradient);
		}
		&[name*='Admin'] {
			background: var(--admin-gradient);
		}
		&[name='Valuation'] {
			background: var(--valuation-gradient);
		}
		i {
			color: var(--gray-lightest);
		}
	}
	&:hover {
		// background: var(--black);
		img {
			filter: var(--white-shadow);
			height: 37px;
		}
		i {
			filter: var(--white-shadow);
			font-size: 28px;
		}
	}
}
