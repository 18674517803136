$content-height: calc(100vh - (#{var(--header-height)}));
.app-loading {
	display: flex;
	background: var(--gray-darkest);
	align-items: center;
	justify-content: center;
	height: 100vh;
}
.app-layout {
	display: grid;
	grid-template-rows: var(--header-height) $content-height;
	grid-template-columns:
		calc(var(--sidebar-width) + var(--sidebar-expander-width))
		1fr;
	background-color: var(--bg-1);
	overflow: auto;
	grid-template-areas:
		'sidebar header'
		'sidebar main';
	.main-container {
		grid-area: main;
		transition: all 300ms ease;
		&.push-content {
			margin-left: calc(
				var(--expanded-sidebar-width) - var(--sidebar-expander-width)
			);
			.routes {
				padding: 0;
			}
		}
		.routes {
			height: $content-height;
			padding: 0 0 0 var(--sidebar-expander-width);
		}
	}
	&.logged-out {
		grid-template-columns: 1fr;
		grid-template-areas:
			'header'
			'main';
		.main-container {
			margin-left: 0;
			.routes {
				padding: 0;
			}
		}
	}
	.version {
		color: var(--text-muted);
		font-size: 12px;
		text-align: center;
	}
}

/* body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
} */
