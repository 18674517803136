$icon-height: 16px;

.app-header {
	background-color: var(--header-bg);
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: var(--header-height);
	grid-area: header;
	&.logged-out {
		.header-left {
			display: none;
		}
		.header-right {
			.icon-alert {
				display: none;
			}
			.user-options {
				display: none;
			}
		}
	}
	.header-left {
	}
	.header-right {
		min-width: 225px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.logo {
			height: 20px;
			padding: 0 30px;
		}
		.logged-in-user {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.header-container {
				display: flex;
				align-items: center;
				justify-content: center;
				height: var(--header-height);
				width: var(--header-height);
			}
			.icon-alert {
				color: var(--white);
				height: $icon-height;
				font-size: $icon-height;
			}
			.user-options {
				margin-right: 10px;
			}
			.change-password-modal {
				position: absolute;
				z-index: 3;
				top: -55px;
				right: 0;
			}
		}
	}

	//   .hamburger {
	//     display: none;
	//     @media only screen and (max-width: 700px) {
	//       display: block;
	//       padding-left: 15px;
	//     }
	//   }

	//   .hamburger:before {
	//     @media only screen and (max-width: 700px) {
	//       content: "";
	//       position: absolute;
	//       top: 0.7em;
	//       width: 1em;
	//       height: 0.15em;
	//       background: #5c5c5c;
	//       box-shadow:
	//         0 0.25em 0 0 #5c5c5c,
	//         0 0.5em 0 0 #5c5c5c;
	//       font-size: 2.5rem;
	//     }
	//   }

	//   .header-dropdown {
	//     display: none;
	//     @media only screen and (max-width: 700px) {
	//       display: block;
	//       margin-left: 10%;
	//       font-size: 1.5rem;
	//       padding: 0;
	//       background-color: transparent;
	//       border: solid 1px #5c5c5c;
	//     }
	//   }

	//   .header-option {
	//     background-color: transparent;
	//     border: none;
	//   }
}
