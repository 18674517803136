.data-badge {
	max-width: 300px;
	display: flex;
	flex-direction: column;
	white-space: normal;
	div:not(:nth-child(1)) {
		margin-top: 5px;
	}
	.name {
		font-size: larger;
	}
	.description {
		// font-size: smaller;
		color: var(--text-muted);
	}
}
