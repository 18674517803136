.date-range-selector {
	display: flex;
	align-items: center;
	width: 400px;
	input {
		text-align: center;
		background: var(--bg-2);
		font: var(--font-size-default), var(--default-font);
		border: 1px solid var(--border-color);
		border-radius: 1px;
		color: var(--text);
		font-size: inherit;
		width: 100%;
		padding: 5px;
	}
	.date-submit {
		margin-left: 5px;
		border-radius: 1px;
		padding: 5px;
	}
	.label {
		color: var(--text-muted);
		margin-right: 5px;
	}
}
