.filter-dropdown {
	position: relative;
	.filter-btn {
		display: flex;
		justify-content: space-between;
		cursor: default;
		color: var(--text);
		&.dark {
			color: var(--navigation-text);
			background: var(--header-panel-bg);
			&:hover {
				border: none;
				background: var(--header-panel-hover);
			}
			&:focus {
				outline-color: var(--gray-lighter);
			}
			&.active {
				outline: none;
				background: var(--header-panel-bg);
				box-shadow: 0 0 5px 5px var(--box-shadow-color);
				border-color: var(--border-color);
			}
		}
		&:hover {
			outline: none;
			background: var(--gray-hover);
			border-color: var(--border-color);
			box-shadow: 0 0 5px 5px var(--box-shadow-color);
		}
		&.active {
			outline: none;
			// background: var(--bg-1);
			box-shadow: 0 0 5px 5px var(--box-shadow-color);
			border-color: var(--border-color);
		}
	}
	.filter-menu {
		display: none;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 10px;
		position: absolute;
		z-index: 2;
		background: var(--bg-2);
		width: fit-content;
		border: 1px solid var(--border-color);
		box-shadow: 2px 3px 3px 2px var(--box-shadow-color);
		[class*='filter']:not(:nth-child(1)) {
			margin-top: 10px;
		}
		&.show {
			display: flex;
		}
	}
}
