.basic-table {
	.default {
		border: 0;
		width: 100%;
		border-collapse: collapse;
		color: var(--data-table-text);
		th {
			color: var(--data-table-header-text);
			font-weight: normal;
		}
		td,
		th {
			padding: 5px 10px 5px 10px;
			font-size: var(--font-size-smallish);
		}
	}

	.-striped {
		tr:nth-child(even) {
			background: var(--data-table-bg-light);
		}
		tr:nth-child(odd) {
			background: var(--data-table-bg-dark);
		}
	}

	.-highlight tr:hover {
		background: var(--primary);
		color: var(--white);
	}
}
