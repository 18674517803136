.toggle {
	width: 32px;
	height: 16px;
	border-radius: 8px;
	border: 0;
	display: flex;
	cursor: pointer;
	justify-content: flex-start;
	align-items: center;
	background-color: var(--toggle-bg);
	position: relative;
	margin: 10px;
	&.selected {
		justify-content: flex-end;
		background-color: var(--primary);
	}
	.indicator {
		width: 14px;
		height: 14px;
		background-color: white;
		border: 0;
		border-radius: 8px;
		margin: 1px;
	}
}
