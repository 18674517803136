.panel {
	border: var(--panel-border);
	.panel-header {
		background: var(--header-panel-bg);
		color: var(--header-panel-text);
		padding: 15px;
		border-bottom: 1px solid var(--border-color);
		grid-row: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		&.no-border {
			border-bottom: none;
		}
		&.no-padding {
			padding: 0;
		}
	}

	.logo {
		margin-top: 5px;
	}

	.panel-body {
		padding: 35px;
		display: flex;
		flex-direction: column;
		background: var(--panel-body);
		&.no-padding {
			padding: 0;
		}
	}

	.panel-body > *:not(:last-child) {
		margin-bottom: 15px;
	}

	.panel-footer {
		text-align: center;
		display: flex;
		flex-direction: column;
		padding: 10px 10px 20px 10px;
		font-size: smaller;
		font-weight: bold;
		background: var(--footer-panel-bg);
		// .link-container {
		//   display: flex;
		//   flex-direction: column;
		//   justify-content: center;
		.link {
			&:hover {
				color: var(--primary-dark);
			}
		}
		// }
	}
}
