.navigation-btn {
	margin-left: 10px;
	margin-right: 10px;
	// padding: 5px 8px;
	&.rounded {
		border-radius: 25px;
	}
	.btn-content {
		display: flex;
		justify-content: center;
		.arrow {
			display: flex;
			justify-content: center;
			&.forward {
				transform: rotate(90deg);
			}
			&.back {
				transform: rotate(-90deg);
			}
		}
		.btn-text {
			margin-left: 5px;
		}
	}
}
