.delete-confirmation {
	display: flex;
	flex-direction: column;
	padding: 25px;
	border: 1px solid var(--border-color);
	background-color: var(--panel-body);
	.delete-confirmation-header {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.exit-btn {
			border: none;
			font-weight: bold;
		}
	}
	.delete-confirmation-prompt {
		padding: 20px 0px 20px 0px;
		font-weight: 600;
	}
	.delete-confirmation-buttons {
		display: grid;
		grid-gap: 10px;
		grid-template-columns: 1fr 1fr;
	}
}
