.shadowbox {
	overflow: hidden;
	.container {
		display: none; /* Hidden by default "block" will show it */
		position: relative; /* Stay in place */
		z-index: 100; /* Sit on top */
		overflow: auto; /* Enable scroll if needed */
		background-color: rgb(0, 0, 0); /* Fallback color */
		background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
		height: 0;
		width: 0;
		overflow: hidden;

		justify-content: center;
		align-items: center;
	}

	.visible {
		display: flex;
		overflow: hidden;
	}
}
