.reach-tooltip {
	background: var(--bg-1);
	color: var(--text);
	border: 1px solid var(--border-color);
}
.tooltip-question-button {
	background: transparent;
	border: none;
	color: var(--text);
	i {
		color: var(--text-muted);
	}
}
