.new-shadow-box {
	display: flex;
	position: relative;
	z-index: 1;
	height: 100%;
	.translucent-bg {
		width: 100%;
		height: 100%;
		position: absolute;
		background: black;
		opacity: 0.8;
		z-index: 2;
		&.show {
			display: flex;
		}
	}
	.modal {
		display: flex;
		position: absolute;
		max-height: 100%;
		z-index: 3;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}
