.arrow-indicator {
	padding: 0 5px;
	cursor: pointer;
	i {
		border: solid var(--text);
		border-width: 0 2px 2px 0;
		display: inline-block;
		margin-bottom: 3px;
		&.arrow-down {
			transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
		}
		&.arrow-up {
			margin-bottom: 0;
			transform: rotate(-135deg);
			-webkit-transform: rotate(-135deg);
		}
		&.arrow-right {
			transform: rotate(-45deg);
			-webkit-transform: rotate(-45deg);
		}
		&.arrow-left {
			margin-left: 5px;
			transform: rotate(135deg);
			-webkit-transform: rotate(135deg);
		}
	}
}
