.qtable-container {
	width: 100%;
	.search-bar-container {
		margin-bottom: 10px;
		padding-left: 10px;
		width: 40%;
		display: flex;
		align-items: center;
		border: 1px solid var(--border-color);
		background: var(--bg-2);
	}
	.q-table {
		width: 100%;
		color: var(--data-table-text);
		background: var(--data-table-bg-dark);
		font-size: var(--font-size-smallish);
		color: var(--text);
		border-collapse: collapse;
		.qtable-column-header {
			font-weight: normal;
			padding: 15px;
			.sorting-button {
				background: none;
				border: none;
			}
		}
		.qtable-column-header:not(:first-child) {
			border-left: 1px solid var(--border-color);
		}
		.individual-cell {
			text-align: center;
			align-items: center;
			padding: 5px;
			position: relative;
			overflow: hidden;
		}
		.individual-cell.no-cell-resizing {
			width: auto !important;
			padding: 5px;
		}
		.resizer {
			display: inline-block;
			width: 5px;
			height: 100%;
			position: absolute;
			right: 0;
			top: 0;
			transform: translateX(50%);
			z-index: 1;
			touch-action: none;
		}
		&.-striped {
			tbody {
				tr:nth-child(odd) {
					background: var(--data-table-bg-light);
				}
			}
		}
	}
	.no-data-warning {
		width: 100%;
		height: 5vh;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: var(--font-size-large);
	}
}
