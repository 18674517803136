.sidebar-menu {
	display: flex;
	position: fixed;
	z-index: 4;
	left: var(--sidebar-width);
	height: 100vh;

	.mouse-overlay-hover {
		width: calc(var(--sidebar-width) - 30px);
		height: 100%;
		// position: absolute;
		.expandable-menu {
			background: var(--sidebar-menu);
			width: var(--sidebar-expander-width);
			transition: width 250ms ease;
			box-shadow: 16px 0px 40px 0px var(--box-shadow-color);
			height: 100%;
			div {
				visibility: hidden;
				opacity: 0;
				transition: opacity 150ms ease;
				&.show {
					visibility: visible;
					opacity: 1;
				}
			}
			.module-heading {
				color: var(--navigation-text);
				display: flex;
				height: 86px;
				align-items: center;
				justify-content: center;
				font-weight: bold;
				font-size: var(--font-size-large);
			}
			.pages {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				.module-page {
					padding: 0px 10px;
					height: 36px;
					width: calc(var(--expanded-sidebar-width) - 15px);
					color: var(--navigation-text-muted);
					font-weight: bold;
					display: flex;
					align-items: center;
					cursor: pointer;
					&.selected {
						background: var(--header-panel-hover);
						color: var(--navigation-text);
					}
					&:hover {
						background: var(--primary);
						color: var(--white);
					}
					&.unauthorized {
						display: none;
					}
					&.disabled {
						color: var(--text-muted);
						font-style: italic;
					}
				}
			}
			&.expanded,
			&.overlay {
				width: var(--expanded-sidebar-width);
				&:hover {
					position: static;
				}
			}
		}
		&.expanded {
			width: var(--expanded-sidebar-width);
			&:hover {
				position: static;
			}
		}
		&.show + .expander-arrow {
			position: absolute;
			left: calc(var(--expanded-sidebar-width) - 25px);
		}
		&.expanded + .expander-arrow {
			position: absolute;
			left: calc(var(--expanded-sidebar-width) - 25px);
			&:hover {
				opacity: 1;
			}
		}
	}
}
