.options-indicator {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	.dot {
		background-color: var(--text);
		height: 4px;
		width: 4px;
		border-radius: 50%;
		margin: 1px;
	}
}
