.pill {
	border-radius: 50px;
	display: flex;
	border: 1px solid var(--text-muted);
	align-items: center;
	justify-content: space-between;
	padding: 2px 6px;
	width: fit-content;
	.label {
		padding: 0px 8px 0 6px;
		margin: 1px;
		text-align: center;
	}
	.exit-btn {
		border: none;
		border-radius: 50%;
		background-color: transparent;
		color: var(--text-muted);
		display: flex;
		padding: 2px;
		cursor: pointer;
		&:focus {
			outline: none;
			box-shadow: 0 0 0 0.5px var(--text-muted);
			color: var(--text);
		}
		&:hover {
			color: var(--text);
		}
	}
}
