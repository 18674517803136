.upload-zone {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin: 10px;
	height: 112px;
	background-image: linear-gradient(
			to right,
			var(--border-color) 50%,
			transparent 50%
		),
		linear-gradient(to right, var(--border-color) 50%, transparent 50%),
		linear-gradient(to bottom, var(--border-color) 50%, transparent 50%),
		linear-gradient(to bottom, var(--border-color) 50%, transparent 50%);
	background-position: left top, left bottom, left top, right top;
	background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
	background-size: 10px 1px, 10px 1px, 1px 10px, 1px 10px;
	width: 98%;
	&:hover {
		opacity: 0.5;
	}
	&.drag-over {
		background-image: linear-gradient(
				to right,
				var(--text) 50%,
				transparent 50%
			),
			linear-gradient(to right, var(--text) 50%, transparent 50%),
			linear-gradient(to bottom, var(--text) 50%, transparent 50%),
			linear-gradient(to bottom, var(--text) 50%, transparent 50%);
	}
	.content {
		.options {
			display: flex;
			margin: 5px;
			i {
				font-size: var(--font-size-larger);
				margin-right: 5px;
			}
			.option {
				display: flex;
				align-items: center;
			}
			.or {
				margin: 0 20px;
				color: var(--text-muted);
			}
		}
		.file-types {
			display: flex;
			margin: 5px;
			justify-content: center;
			color: var(--text-muted);
		}
	}
	.hidden {
		position: absolute;
		left: -1000px;
		top: -1000px;
	}
}
