.date-range-slider {
	display: flex;
	flex-direction: column;
	fieldset {
		border: none;
		margin: 0;
		padding: 0;
	}
	.slider-submit-btn {
		align-self: flex-end;
		font-size: var(--smaller);
	}
	.date-range-picker {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		[class^='input'] {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			width: 100%;
			margin-top: 10px;
			&:not(:first-child) {
				label {
					margin-right: 17px;
				}
			}
			&:first-child {
				label {
					margin-right: 10px;
				}
			}
		}
		input {
			padding-right: 10px;
		}
	}
}
