.result-box {
	text-align: center;
	.panel-body {
		align-items: center;
	}
	.heading {
		margin-block-start: 0;
	}
	.body {
		font-size: larger;
		margin-top: 15px;
		padding: 0 5px;
	}
	[class*='-icon'] {
		margin: 15px;
		width: fit-content;
	}
	.success-icon {
		border: 5px solid var(--success);
		border-radius: 75px;
		padding: 8px 18px;
		color: var(--success);
		font-size: 6rem;
	}
	.failure-icon {
		border: 5px solid var(--danger);
		border-radius: 25px;
		padding: 0px 25px;
		color: var(--danger);
		font-size: 8rem;
		font-weight: 700;
	}
	.error-icon {
		border-color: var(--purple);
		color: var(--purple);
		font-size: 10rem;
	}
}
