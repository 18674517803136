.btn {
	padding: var(--padding-default);
	vertical-align: middle;
	align-content: center;
	align-items: center;
	text-align: center;
	display: inline;
	transition-duration: 0.2s;
	font-size: var(--font-size-default);
	white-space: nowrap;
	border-radius: 1px;
	border: 1px solid var(--border-color);
	color: var(--text);
	// background: var(--button-bg);
	background: transparent;
	&:hover {
		background: var(--gray-hover);
		border: 1px solid var(--border-color);
		cursor: pointer;
	}

	&:active {
		transform: translateY(2px);
		animation: clickanim;
		animation-duration: 0.2s;
	}

	@keyframes clickanim {
		0% {
			transform: translatey(0px);
		}
		50% {
			transform: translatey(2px);
		}
		100% {
			transform: translatey(0px);
		}
	}
}

.btn-fill {
	color: var(--button-text);
	background: var(--primary);
	border: 1px solid var(--primary);
	&:hover {
		background: var(--primary-dark);
		border: 1px solid var(--primary-dark);
	}
}

.btn-green {
	border: 1px solid var(--green);
	color: var(--green);
	background: transparent;
	&:hover {
		background: var(--green);
	}
}

.btn-red {
	border: 1px solid var(--red);
	color: var(--red);
	background: transparent;
	&:hover {
		color: var(--white);
		background: var(--red);
	}
}

.btn-blue {
	color: var(--white);
	background: var(--blue);
}

.btn-orange {
	border: 1px solid var(--orange);
	color: var(--orange);
	background: transparent;
	&:hover {
		color: var(--white);
		background: var(--orange);
	}
}

.btn-white {
	background-color: transparent;
	color: var(--white);
	border: 2px solid var(--white);
	&:hover {
		background: var(--white);
		color: (--text);
	}
}

.btn-muted {
	border: 1px solid var(--border-color);
	background-color: transparent;
	color: var(--text-muted);
}

.btn-bones {
	border: 1px solid var(--border-color);
	background-color: transparent;
	color: var(--text);
}

.btn-no-theme {
	color: white;
	border-color: var(--border-color);
	&:hover {
		background: var(--gray);
		border-color: var(--border-color);
	}
}

button:disabled {
	opacity: 0.6;
	background: var(--button-disabled);
	border: none;
	color: var(--text-muted);
	&:hover {
		cursor: not-allowed;
	}

	&:hover {
		// animation-name: disabledanim;
		// animation-duration: 0.2s;
		background: var(--button-disabled);
		border: none;
		color: var(--text-muted);
	}

	@keyframes disabledanim {
		0% {
			transform: translatex(0px);
		}
		25% {
			transform: translatex(-4px);
		}
		50% {
			transform: translatex(4px);
		}
		100% {
			transform: translatex(0px);
		}
	}
}
