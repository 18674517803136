.confirmation-alert {
	background: var(--gray);
	border-color: var(--gray);
	.alert-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		.btn {
			margin: 10px;
			width: 65px;
			// &:hover {
			//   background-color: none;
			// }
		}
	}
}
