.draggable-detail {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 2px;
	.col {
		grid-gap: 2px;
		display: grid;
		.dot {
			background: var(--border-color);
			border-radius: 25px;
			height: 2px;
			width: 2px;
		}
	}
}
