.logo-box {
	width: 365px;
	box-shadow: 0px 0px 4px 2px black;
	// Needs to be its own component eventually
	.form {
		display: grid;
		grid-gap: 10px;
		.btn-container {
			padding-top: 10px;
			display: grid;
			grid-gap: 10px;
			button {
				width: 100%;
			}
		}
	}
}
