.login-form {
	&.lender {
		.panel-body {
			background-color: var(--bg-3);
		}
		.form {
			input {
				border: 1px solid var(--lt-gray-light);
				background: var(--white);
				color: var(--lt-gray-darkest);
			}
		}
		.panel-footer {
			background-color: var(--white);
		}
	}
	.btn-container {
		.login-loading-animation {
			bottom: 36px;
			right: 32px;
			height: 0;
			width: 0;
		}
	}
	.link-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}
