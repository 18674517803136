.new-loader {
	height: 100%;
	width: 100%;
	background: var(--loading-color);
	position: absolute;
	z-index: 2;
	display: none;
	align-items: center;
	justify-content: center;
	&.visible {
		display: flex;
	}
}
