.data-filter-layout {
	display: grid;
	grid-gap: 10px;
	margin: 10px;
	height: calc(100vh - (#{var(--header-height)}) - 20px);
	grid-template-rows: var(--data-filter-height) 1fr;
	@media screen and (max-width: 1160px) {
		grid-template-rows: 118px 1fr;
	}
}
