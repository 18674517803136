.no-margin {
	margin: 0;
}

.no-padding {
	padding: 0;
}

/* Margin Helpers */
.m-t-1 {
	margin-top: 10px;
}

.m-t-2 {
	margin-top: 20px;
}

.m-t-3 {
	margin-top: 30px;
}

.m-b-1 {
	margin-bottom: 10px;
}

.m-b-2 {
	margin-bottom: 20px;
}

.m-b-3 {
	margin-bottom: 30px;
}

.m-1 {
	margin: 10px;
}

.m-2 {
	margin: 20px;
}

.m-3 {
	margin: 30px;
}

.m-r-1 {
	margin-right: 10px;
}

.m-r-2 {
	margin-right: 20px;
}

.m-r-3 {
	margin-right: 30px;
}

.m-l-1 {
	margin-left: 10px;
}

.m-l-2 {
	margin-left: 30px;
}

.m-l-3 {
	margin-left: 30px;
}

/* Padding Helpers */

.p-1 {
	padding: 10px;
}

.p-2 {
	padding: 20px;
}

.p-3 {
	padding: 30px;
}

/* Align Helpers */

.align-left {
	text-align: left;
}

.align-center {
	text-align: center;
}

.align-right {
	text-align: right;
}

.relative-container {
	position: relative;
}

/* Debug Styles */

.bg-indicator {
	background-color: lightgreen;
}

.bg-indicator-1 {
	background-color: rgb(179, 57, 240);
}

.sr-only {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
